import { motion } from 'framer-motion';
import { Shield, Clock, Award, UserCheck, Wrench, BadgeCheck } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: "Trusted Expertise",
    description: "Expert inspections you can rely on"
  },
  {
    icon: Clock,
    title: "Time-Efficient",
    description: "Quick turnaround without compromising quality"
  },
  {
    icon: Award,
    title: "Certified Professionals",
    description: "Highly trained and certified inspectors"
  },
  {
    icon: UserCheck,
    title: "Customer Satisfaction",
    description: "Dedicated to exceeding expectations"
  },
  {
    icon: Wrench,
    title: "Advanced Equipment",
    description: "State-of-the-art diagnostic tools"
  },
  {
    icon: BadgeCheck,
    title: "Quality Guaranteed",
    description: "Thorough and reliable inspections"
  }
];

const iconVariants = {
  initial: {
    scale: 0,
    rotate: -180,
  },
  animate: {
    scale: 1,
    rotate: 0,
    transition: {
      type: "spring",
      stiffness: 260,
      damping: 20,
    }
  },
  exit: {
    scale: 0,
    rotate: 180,
    transition: {
      duration: 0.5,
      ease: "easeInOut"
    }
  },
  hover: {
    scale: 1.2,
    rotate: [0, -10, 10, -10, 0],
    transition: {
      duration: 0.5,
      ease: "easeInOut"
    }
  }
};

const contentVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3,
      ease: "easeIn"
    }
  },
  hover: {
    y: -5,
    transition: {
      duration: 0.2,
      ease: "easeOut"
    }
  }
};

export default function ScrollingBanner() {
  const logo = {
    isLogo: true,
    image: "https://i.ibb.co/rfR4yw3/Logo-cleaned.png"
  };

  const featuresWithLogos = [];
  for (let i = 0; i < features.length; i++) {
    featuresWithLogos.push(features[i]);
    if ((i + 1) % 4 === 0) {
      featuresWithLogos.push(logo);
    }
  }

  return (
    <div className="bg-[#0f172a] py-4 overflow-hidden">
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 w-12 bg-gradient-to-r from-[#0f172a] to-transparent z-10" />
        <div className="absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-[#0f172a] to-transparent z-10" />
        <motion.div
          className="flex space-x-16"
          animate={{
            x: [0, -2000]
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 45,
              ease: "linear"
            }
          }}
        >
          {[...featuresWithLogos, ...featuresWithLogos].map((item, index) => (
            <motion.div
              key={index}
              className="inline-flex items-center space-x-4 px-6 flex-shrink-0 group cursor-pointer"
              initial="initial"
              whileInView="animate"
              exit="exit"
              whileHover="hover"
              viewport={{ once: false, amount: 0.8 }}
            >
              {'isLogo' in item ? (
                <motion.div
                  variants={iconVariants}
                  className="relative"
                >
                  <img 
                    src={item.image} 
                    alt="C.A.R.S Logo" 
                    className="w-16 h-16 object-contain"
                  />
                </motion.div>
              ) : (
                <>
                  <motion.div
                    variants={iconVariants}
                    className="relative"
                  >
                    <item.icon className="w-12 h-12 text-cars-red flex-shrink-0" />
                    <motion.div
                      className="absolute -inset-2 bg-cars-red/10 rounded-full -z-10"
                      initial={{ opacity: 0, scale: 0.8 }}
                      whileHover={{ 
                        opacity: 1, 
                        scale: 1.2,
                        transition: {
                          duration: 0.3
                        }
                      }}
                    />
                  </motion.div>
                  <motion.div 
                    className="whitespace-nowrap"
                    variants={contentVariants}
                  >
                    <h3 className="font-semibold text-lg text-white group-hover:text-cars-red transition-colors">
                      {item.title}
                    </h3>
                    <p className="text-sm text-gray-300">{item.description}</p>
                  </motion.div>
                </>
              )}
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export const prePurchasePackages = [
  {
    title: 'Essential Check',
    price: 149.99,
    features: [
      'Visual body condition assessment',
      'Basic mechanical inspection',
      'Fluid level checks',
      'Tire condition check',
      '50+ point checklist'
    ],
    details: {
      visualBody: "A thorough visual inspection of the vehicle's exterior and interior condition, including paint, body panels, glass, and trim.",
      mechanical: "Basic mechanical inspection covering major components and systems to identify obvious issues.",
      fluids: "Visual inspection of all major fluid levels including oil, coolant, brake fluid, and power steering fluid.",
      tires: "Visual inspection of tire condition, tread depth measurement, and pressure check.",
      checklist: "A comprehensive 50+ point inspection checklist covering essential vehicle systems and components."
    }
  },
  {
    title: 'Complete Condition Report',
    price: 249.99,
    features: [
      'Basic Check Engine OBD2 Scan',
      'Battery Health Test (12V)',
      'Brake Measurement',
      'Suspension Inspection',
      '100+ Point Checklist'
    ],
    details: {
      visualBody: "Basic OBD2 diagnostic scan to check for stored trouble codes and monitor basic engine parameters.",
      mechanical: "Battery health assessment using electronic testing equipment to measure voltage, cold cranking amps, and overall condition.",
      fluids: "Detailed brake system inspection including rotor and pad measurements using precision tools.",
      tires: "Comprehensive suspension inspection checking for wear, damage, and potential safety issues.",
      checklist: "Thorough 100+ point inspection covering all major vehicle systems and components."
    }
  },
  {
    title: 'C.A.R.S Signature Package',
    price: 399.99,
    features: [
      'Full System Diagnostic Scan',
      'Laser Tire Scanning',
      'Video Documentation',
      'Full Battery Starting and Charging Report',
      '150+ Point Checklist'
    ],
    details: {
      visualBody: "Advanced diagnostic scan using professional-grade equipment to evaluate all vehicle systems and modules.",
      mechanical: "Laser-based tire analysis providing detailed measurements of tread depth, wear patterns, and alignment indicators.",
      fluids: "Comprehensive video documentation of the inspection process and findings with expert commentary.",
      tires: "Complete battery and charging system analysis including alternator output and starter performance.",
      checklist: "Extensive 150+ point inspection with detailed documentation and professional recommendations."
    },
    isSignature: true
  },
  {
    title: 'Elite Package',
    price: 749.99,
    features: [
      'Factory-Level Testing',
      'Fluid Analysis',
      'Interactive Digital Report',
      'Phone Consultation',
      '200+ Point Checklist'
    ],
    details: {
      visualBody: "A comprehensive diagnostic process using manufacturer-specific tools and software to evaluate your vehicle's electronic systems, sensors, and computerized components. This testing mirrors the depth and precision of inspections performed at authorized dealerships, identifying both current issues and potential future concerns.",
      mechanical: "Onsite fluid analysis encompasses three essential diagnostics to ensure vehicle system integrity. Head gasket integrity is assessed by detecting combustion gases in the coolant using a block tester that samples radiator vapor at operating temperature; a color change from blue to yellow indicates a gasket leak. Brake fluid is evaluated for water content with an electronic moisture meter, where levels exceeding 3% require immediate fluid replacement to maintain braking performance. Coolant integrity is determined through pH testing, freeze point measurement using a refractometer, specific gravity assessment, and visual inspections for contamination. Conducted with calibrated equipment, these procedures provide accurate, actionable data to support maintenance decisions in alignment with manufacturer specifications.",
      fluids: "An interactive digital report is a cutting-edge, user-friendly tool designed to present the outcomes of an automotive inspection in a clear and accessible manner. This report can be accessed on a smartphone, tablet, or computer and offers a comprehensive overview of the vehicle's condition. It includes detailed information about key components, such as the engine, transmission, and brakes, and is often enhanced with images, videos, and diagrams to facilitate understanding. The interactive nature of the report enables users to navigate through the findings effortlessly and access additional details with a simple click.",
      tires: "Phone consultation provides vehicle owners with the opportunity to discuss inspection results, maintenance options, and any concerns directly with automotive experts. This service allows for personalized advice and clarification of technical information, ensuring that owners fully understand the necessary steps to maintain their vehicle's performance and safety. Phone consultations offer convenience and expert support without the need for an in-person visit.",
      checklist: "A 200+ point checklist is an exhaustive list of inspection criteria that covers every aspect of a vehicle's condition, from mechanical components to exterior and interior features. This thorough examination ensures that no detail is overlooked, providing a complete assessment of the vehicle's health and identifying any areas that require attention. Utilizing a 200+ point checklist guarantees a meticulous inspection, enhancing the accuracy and reliability of the overall vehicle evaluation."
    }
  }
];

export const seasonalServices = [
  {
    title: "Spring-Forward",
    price: 139.99,
    features: [
      "Post-winter system analysis",
      "Brake & suspension test",
      "Fluid systems evaluation",
      "Spring-Forward report"
    ],
    details: {
      description: "Shake off the winter blues and embrace the open road with our Spring Forward Vehicle Revitalization Package. After months of harsh weather, ensure your vehicle is ready for warmer adventures with our comprehensive spring maintenance service. We'll perform a revitalizing AC system check to keep you cool and comfortable on those upcoming sunny days, a thorough suspension inspection to guarantee a smooth and responsive ride, and a meticulous brake system test for optimal safety and stopping power. You'll also receive a detailed Spring Forward Report outlining a comprehensive tire check for wear and pressure, a complete fluid level inspection, and a visual leak check to identify and address any potential issues. Get your vehicle spring-ready and enjoy peak performance and peace of mind all season long."
    }
  },
  {
    title: 'Summer Ready',
    price: 169.99,
    features: [
      'Climate control analysis',
      'Cooling system test',
      'Heat stress evaluation',
      'Summer Ready report'
    ],
    details: {
      description: "Ensure your vehicle is primed for the sweltering summer months with our Summer Ready package, designed to maximize performance, safety, and comfort. Our expert technicians will conduct a thorough cooling system test to prevent overheating and engine damage, as well as an AC performance check to guarantee your air conditioning system is blowing cold air efficiently. A comprehensive battery load test will be performed to ensure your battery can handle the high temperatures, while a meticulous brakes and tire inspection will verify that your vehicle's stopping power and traction are optimal. The centerpiece of our service is the Summer Readiness Report, which includes detailed tire checks for proper inflation and tread depth, brake pad and rotor evaluations, a thorough assessment of essential fluid levels, and a visual inspection to identify any potential leaks or wear on critical components. By investing in our Summer Ready package, you'll not only enhance your vehicle's performance and fuel efficiency but also safeguard against unexpected breakdowns and ensure a safe, comfortable driving experience throughout the hottest months of the year."
    }
  },
  {
    title: 'Autumn Safety',
    price: 159.99,
    features: [
      'Cold weather preparation',
      'Battery performance test',
      'Visibility systems check',
      'Autumn Safety report'
    ],
    details: {
      description: "Transition smoothly into cooler weather with our Autumn Safety Package, designed to prepare your vehicle for the challenges of fall and the approaching winter. Our skilled technicians will perform a comprehensive heater system inspection to ensure cozy warmth on crisp autumn evenings and frigid winter days. We'll conduct a thorough battery test to safeguard against cold-weather starting issues and perform a meticulous tire inspection to guarantee optimal grip on potentially slick fall and winter roads. We also include a wiper blade inspection for maximum visibility during rain and early snow, and a vital antifreeze test to protect your engine against freezing temperatures. Furthermore, our detailed Autumn Safety Prep Report provides a comprehensive overview of your vehicle's readiness, including tire condition and pressure, brake system performance, fluid levels, and a visual inspection for leaks, ensuring your vehicle is safe and reliable throughout the fall and into the winter months."
    }
  },
  {
    title: 'Winter Shield',
    price: 169.99,
    features: [
      'Cold start analysis',
      'Heating system test',
      'Traction assessment',
      'Winter Shield report'
    ],
    details: {
      description: "Prepare your vehicle for the unforgiving winter season with our Winter Readiness Protection Package. Our skilled mobile technicians will conduct a comprehensive diagnostic service designed to safeguard your vehicle against cold-weather challenges. We'll perform a thorough Tire Pressure Monitoring System (TPMS) evaluation to ensure optimal tire performance in changing temperatures, a critical coolant system check to prevent freezing and maintain engine health, and a specialized battery diagnostic to eliminate the risk of electrical failures during cold snaps. Our expert team will also meticulously inspect your brake system for winter resilience, verify all essential fluid levels to protect vital vehicle components, and complete a detailed visual inspection to identify and address potential leaks or vulnerabilities that could compromise your vehicle's winter performance and safety."
    }
  }
];

export const diagnosticServices = [
  {
    title: 'Professional Diagnostic Service',
    price: 199.99,
    rateType: 'hourly',
    description: 'Our state-of-the-art diagnostic service provides thorough analysis of your vehicle\'s electronic and mechanical systems using professional-grade equipment.',
    features: [
      'Full system diagnostic scan',
      'Live data analysis & testing',
      'Circuit & component testing',
      'Comprehensive diagnostic report'
    ],
    details: {
      description: "Our Professional Diagnostic Service provides a thorough analysis of your vehicle's electronic and mechanical systems using state-of-the-art diagnostic equipment. Our certified technicians will perform a comprehensive scan of all vehicle systems, conduct live data analysis to identify any current or potential issues, and perform detailed circuit and component testing where necessary. You'll receive a detailed diagnostic report that includes trouble codes, system performance data, and expert recommendations for addressing any identified issues. This service combines manufacturer-specific testing capabilities with advanced diagnostic techniques to deliver accurate, actionable insights about your vehicle's health. Service is billed at an hourly rate, with most diagnostic sessions completed within 1-2 hours depending on the complexity of the issue.",
      scan: "Comprehensive scan of all vehicle systems and modules using professional-grade diagnostic equipment",
      analysis: "Real-time data analysis and advanced testing procedures to identify issues and verify system operation",
      testing: "Detailed circuit and component testing to pinpoint specific problems and verify repairs",
      report: "Complete diagnostic report including trouble codes, system data, and expert recommendations"
    }
  }
];
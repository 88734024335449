import { motion } from 'framer-motion';
import { Shield, Award, Clock, Wrench, Users, Gauge, FileCheck, DollarSign, Car, Battery, Phone, Laptop, MapPin, Star, CheckCircle, Handshake, Settings, Link, Leaf, MessageSquare, BookOpen, Calendar, Image, Bell, History, TrendingUp, AlertCircle, MessageCircle, ClipboardCheck } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useState } from 'react';

export default function WhyChooseUs() {
  const [activeSection, setActiveSection] = useState('mobile');

  const benefitSections = {
    mobile: {
      title: "Mobile Service Benefits",
      icon: <MapPin className="w-16 h-16 text-red-600" />,
      benefits: [
        {
          icon: <Clock className="w-12 h-12" />,
          title: "Convenient On-site Inspections",
          description: "Service at home, office, or preferred location. We bring our expertise directly to you."
        },
        {
          icon: <Car className="w-12 h-12" />,
          title: "Pre-Purchase Mobile Inspections",
          description: "On-location evaluations for used car purchases, making the buying process convenient and secure."
        },
        {
          icon: <Users className="w-12 h-12" />,
          title: "Fleet Inspection Services",
          description: "Minimal downtime for business vehicle fleets with our efficient on-site inspection process."
        },
        {
          icon: <CheckCircle className="w-12 h-12" />,
          title: "Flexible Scheduling",
          description: "Appointments that fit your schedule, including evenings and weekends when available."
        },
        {
          icon: <Award className="w-12 h-12" />,
          title: "Industry Accreditations",
          description: "Professional association memberships and certifications ensuring top-quality service."
        },
        {
          icon: <FileCheck className="w-12 h-12" />,
          title: "Certified Inspectors",
          description: "Our team undergoes continuous training and updates to stay current with industry standards."
        },
        {
          icon: <Link className="w-12 h-12" />,
          title: "Local Business Partnerships",
          description: "Extensive network of automotive service connections for comprehensive support."
        },
        {
          icon: <Settings className="w-12 h-12" />,
          title: "Customizable Inspection Packages",
          description: "Flexible options based on your specific needs and requirements."
        }
      ]
    },
    hybrid: {
      title: "Hybrid/EV Specific Benefits",
      icon: <Battery className="w-16 h-16 text-red-600" />,
      benefits: [
        {
          icon: <Award className="w-12 h-12" />,
          title: "Specialized EV/Hybrid Expertise",
          description: "Technicians certified with Ford and GM High Voltage Certifications for advanced vehicle technologies."
        },
        {
          icon: <Battery className="w-12 h-12" />,
          title: "Energy Storage System Assessment",
          description: "Detailed battery health evaluations and charging efficiency analysis."
        },
        {
          icon: <Shield className="w-12 h-12" />,
          title: "High-Voltage System Safety Checks",
          description: "Thorough inspection of electric components and safety systems."
        },
        {
          icon: <Leaf className="w-12 h-12" />,
          title: "Sustainability Focus",
          description: "Supporting eco-friendly transportation choices with specialized green vehicle services."
        },
        {
          icon: <Gauge className="w-12 h-12" />,
          title: "Performance Optimization",
          description: "Advanced diagnostics to ensure optimal efficiency and performance of hybrid systems."
        },
        {
          icon: <FileCheck className="w-12 h-12" />,
          title: "Comprehensive Documentation",
          description: "Detailed reports on battery health, system performance, and maintenance recommendations."
        },
        {
          icon: <Clock className="w-12 h-12" />,
          title: "Preventive Maintenance",
          description: "Early detection of potential issues specific to hybrid and electric vehicles."
        }
      ]
    },
    digital: {
      title: "Digital & Customer Service Features",
      icon: <Laptop className="w-16 h-16 text-red-600" />,
      benefits: [
        {
          icon: <FileCheck className="w-12 h-12" />,
          title: "Digital Reporting",
          description: "Easy access to comprehensive inspection reports via email or our online portal."
        },
        {
          icon: <DollarSign className="w-12 h-12" />,
          title: "Online Payments",
          description: "User-friendly platform for seamless service requests and secure payment processing."
        },
        {
          icon: <Phone className="w-12 h-12" />,
          title: "Exceptional Customer Support",
          description: "Dedicated support team available before, during, and after your inspection."
        },
        {
          icon: <MessageSquare className="w-12 h-12" />,
          title: "Customer Survey Feedback Loop",
          description: "Regular collection and implementation of client feedback for continuous service improvement."
        },
        {
          icon: <BookOpen className="w-12 h-12" />,
          title: "Educational Content",
          description: "Access to informative articles and resources for vehicle maintenance and care."
        },
        {
          icon: <Image className="w-12 h-12" />,
          title: "Photo Documentation",
          description: "Detailed visual documentation of inspection findings with annotated images."
        },
        {
          icon: <Bell className="w-12 h-12" />,
          title: "Service Notifications",
          description: "Automated updates about your inspection status and maintenance reminders."
        },
        {
          icon: <History className="w-12 h-12" />,
          title: "Service History Tracking",
          description: "Digital record-keeping of all your past inspections and maintenance records."
        }
      ]
    },
    general: {
      title: "General Benefits",
      icon: <Star className="w-16 h-16 text-red-600" />,
      benefits: [
        {
          icon: <Shield className="w-12 h-12" />,
          title: "Peace of Mind",
          description: "Know the true condition of your vehicle through comprehensive, unbiased inspections."
        },
        {
          icon: <TrendingUp className="w-12 h-12" />,
          title: "Increased Resale Value",
          description: "Clean inspection reports enhance vehicle value and attract potential buyers."
        },
        {
          icon: <AlertCircle className="w-12 h-12" />,
          title: "Safety Assurance",
          description: "Thorough identification and documentation of potential safety hazards."
        },
        {
          icon: <MessageCircle className="w-12 h-12" />,
          title: "Transparent Communication",
          description: "Clear, detailed explanations of all inspection findings and recommendations."
        },
        {
          icon: <Clock className="w-12 h-12" />,
          title: "Reliable and Timely Service",
          description: "Punctual appointments and efficient completion of all inspections."
        },
        {
          icon: <Car className="w-12 h-12" />,
          title: "Thorough Test Drives",
          description: "Comprehensive evaluation of vehicle performance and handling characteristics."
        },
        {
          icon: <Wrench className="w-12 h-12" />,
          title: "Expert Diagnostics",
          description: "Advanced diagnostic equipment and expertise to identify complex issues."
        },
        {
          icon: <ClipboardCheck className="w-12 h-12" />,
          title: "Detailed Checklists",
          description: "Comprehensive 200+ point inspection process covering all major systems."
        }
      ]
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative pt-24 pb-16 bg-red-600 text-white">
        <div className="absolute inset-0 bg-black/20" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Why Choose CARS?
            </h1>
            <p className="text-xl text-white/90 max-w-3xl mx-auto">
              Colorado's most trusted vehicle inspection service, combining expertise, 
              technology, and exceptional customer service.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Benefits Sections */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Section Navigation */}
          <div className="flex justify-center items-center space-x-4 mb-12 overflow-x-auto pb-4">
            {Object.entries(benefitSections).map(([key, section]) => (
              <button
                key={key}
                onClick={() => setActiveSection(key)}
                className={`flex flex-col items-center gap-4 px-8 py-4 rounded-lg text-lg font-semibold transition-all whitespace-nowrap
                  border-2 min-w-[250px] justify-center
                  ${activeSection === key 
                    ? 'bg-red-600 text-white border-red-600 shadow-lg' 
                    : 'bg-white text-gray-600 border-gray-300 hover:border-red-600 hover:text-red-600'}`}
              >
                <div className={`${activeSection === key ? 'text-white' : 'text-red-600'}`}>
                  {section.icon}
                </div>
                <span>{section.title}</span>
              </button>
            ))}
          </div>

          {/* Active Section Content */}
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                {benefitSections[activeSection].title}
              </h2>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {benefitSections[activeSection].benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all hover:scale-105"
                >
                  <div className="text-red-600 mb-6 flex justify-center">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600">
                    {benefit.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-red-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to Experience the CARS Difference?
            </h2>
            <p className="text-xl text-white/90 mb-8">
              Book your inspection today and see why we're Colorado's top choice
            </p>
            <RouterLink 
              to="/booking"
              className="inline-block bg-white text-red-600 px-8 py-3 rounded-lg hover:bg-gray-100 transition font-semibold"
            >
              Schedule an Inspection
            </RouterLink>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
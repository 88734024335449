import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const BlogNavbar = () => (
  <nav className="bg-[#1a1f2e] shadow-lg">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <Link to="/" className="flex items-center">
            <img src="https://i.ibb.co/rfR4yw3/Logo-cleaned.png" alt="C.A.R.S Logo" className="h-8 w-8" />
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

export default function Blog() {
  return (
    <div className="min-h-screen bg-gray-100">
      <BlogNavbar />
      <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900">Blog Coming Soon</h1>
        <p className="mt-4 text-lg text-gray-600">
          We are currently working on this section. Please check back later for updates.
        </p>
      </div>
    </div>
  );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'All Services', to: '/services' },
  { name: 'Why Choose Us', to: '/why-choose-us' },
  { name: 'FAQ', to: '/faq' }
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-[#1a1f2e]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <div className="flex items-center">
                <img
                  src="https://i.ibb.co/rfR4yw3/Logo-cleaned.png"
                  alt="C.A.R.S Logo"
                  className="h-16 w-16"
                />
                <div className="ml-2 flex items-end">
                  <span className="text-cars-red text-3xl font-bold tracking-wide leading-none">C.A.R.S</span>
                  <span className="ml-2 text-gray-300 text-base">Colorado Automotive Report & Service</span>
                </div>
              </div>
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
              >
                {item.name}
              </Link>
            ))}
            <Link
              to="/contact"
              className="bg-cars-red text-white px-4 py-2 rounded-md text-base font-medium hover:bg-red-700 transition-colors duration-200"
            >
              Contact Us
            </Link>
          </div>

          <div className="md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {mobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-lg font-medium"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
            <Link
              to="/contact"
              className="text-white bg-cars-red block px-3 py-2 rounded-md text-lg font-medium hover:bg-red-700 transition-colors duration-200"
              onClick={() => setMobileMenuOpen(false)}
            >
              Contact Us
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}
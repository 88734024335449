import { useCallback } from 'react';
import { trackUserEngagement } from '../services/analytics/enhancedAnalytics';

export const useClickTracking = () => {
  const trackClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const element = event.currentTarget;
    
    // Get element details
    const elementType = element.tagName.toLowerCase();
    const elementId = element.id;
    const elementClasses = Array.from(element.classList).join(' ');
    const elementText = element.textContent?.trim();
    
    // Get element role and type
    const role = element.getAttribute('role');
    const type = (element as HTMLInputElement).type;
    
    // Get the nearest data-testid or aria-label
    const testId = element.getAttribute('data-testid');
    const ariaLabel = element.getAttribute('aria-label');
    
    // Get the path to the element
    const path = getElementPath(element);
    
    // Track the click
    trackUserEngagement.buttonClick(elementId || testId || ariaLabel || elementText || 'unknown', {
      element_type: elementType,
      element_id: elementId,
      element_classes: elementClasses,
      element_text: elementText,
      element_role: role,
      element_type_attr: type,
      test_id: testId,
      aria_label: ariaLabel,
      element_path: path,
      x: event.clientX,
      y: event.clientY,
      page_x: event.pageX,
      page_y: event.pageY,
      ctrl_key: event.ctrlKey,
      shift_key: event.shiftKey,
      alt_key: event.altKey,
      meta_key: event.metaKey,
      timestamp: new Date().toISOString()
    });
  }, []);

  return trackClick;
};

// Helper function to get the path to an element
function getElementPath(element: HTMLElement): string {
  const path: string[] = [];
  let currentElement: HTMLElement | null = element;
  
  while (currentElement && currentElement !== document.body) {
    let identifier = currentElement.tagName.toLowerCase();
    
    if (currentElement.id) {
      identifier += `#${currentElement.id}`;
    } else if (currentElement.className) {
      identifier += `.${currentElement.className.split(' ').join('.')}`;
    }
    
    path.unshift(identifier);
    currentElement = currentElement.parentElement;
  }
  
  return path.join(' > ');
}

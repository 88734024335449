import { useState } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Search, Wrench, Thermometer } from 'lucide-react';
import { prePurchasePackages, seasonalServices, diagnosticServices } from '../data/services';
import ServiceModal from '../components/ServiceModal';

interface ServiceCategory {
  title: string;
  description: string;
  icon: any;
  services: any[];
  serviceType: string;
}

const serviceCategories: ServiceCategory[] = [
  {
    title: "Pre-Purchase Inspections",
    description: "Make informed decisions with our comprehensive inspection packages",
    icon: Search,
    services: prePurchasePackages,
    serviceType: "pre-purchase"
  },
  {
    title: "Seasonal Maintenance",
    description: "Keep your vehicle running smoothly all year round",
    icon: Thermometer,
    services: seasonalServices,
    serviceType: "seasonal"
  },
  {
    title: "Diagnostic Services",
    description: "Advanced diagnostics to identify and resolve complex issues",
    icon: Wrench,
    services: diagnosticServices,
    serviceType: "diagnostic"
  }
];

export default function ServicesPage() {
  const [selectedCategory, setSelectedCategory] = useState<ServiceCategory | null>(null);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.h1 
          className="text-4xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Services
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {serviceCategories.map((category, index) => {
            const Icon = category.icon;
            return (
              <motion.div
                key={category.title}
                className="flex flex-col items-center text-center cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                onClick={() => setSelectedCategory(category)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div className="w-40 h-40 rounded-full bg-white shadow-lg flex items-center justify-center mb-6 hover:shadow-xl transition-shadow">
                  <Icon className="w-20 h-20 text-cars-red" />
                </div>
                <h2 className="text-2xl font-bold mb-3">{category.title}</h2>
                <p className="text-gray-600">{category.description}</p>
              </motion.div>
            );
          })}
        </div>
      </div>

      {selectedCategory && (
        <ServiceModal
          isOpen={!!selectedCategory}
          closeModal={() => setSelectedCategory(null)}
          title={selectedCategory.title}
          services={selectedCategory.services}
          serviceType={selectedCategory.serviceType}
        />
      )}

      <Footer />
    </div>
  );
}
import { motion } from 'framer-motion';
import { Car, Calendar, Phone, Clipboard, FileCheck } from 'lucide-react';
import { Link } from 'react-router-dom';
import React from 'react';

interface Step {
  id: number;
  title: string;
  description: string;
  icon: JSX.Element;
}

export default function Process() {
  const steps: Step[] = [
    {
      id: 0,
      title: "Locate Vehicle",
      description: "Find your perfect vehicle and we'll handle the inspection.",
      icon: <Car className="w-10 h-10 text-cars-red" />
    },
    {
      id: 1,
      title: "Schedule",
      description: "Book your inspection through our online portal.",
      icon: <Calendar className="w-10 h-10 text-cars-red" />
    },
    {
      id: 2,
      title: "Coordinate",
      description: "We'll arrange the inspection with the seller.",
      icon: <Phone className="w-10 h-10 text-cars-red" />
    },
    {
      id: 3,
      title: "Inspect",
      description: "Our certified technicians perform a thorough inspection.",
      icon: <Clipboard className="w-10 h-10 text-cars-red" />
    },
    {
      id: 4,
      title: "Report",
      description: "Receive your detailed report within 24 hours.",
      icon: <FileCheck className="w-10 h-10 text-cars-red" />
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-[#1a1f2e] rounded-3xl mx-8 p-12">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">How It All Works</h2>
            <p className="text-xl text-gray-200 max-w-3xl mx-auto">Your journey to a confident car purchase starts here</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-5 gap-x-8 gap-y-16">
            {steps.map((step) => (
              <motion.div
                key={step.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: step.id * 0.1 }}
                className="relative flex flex-col items-center text-center"
              >
                {/* Connector line for desktop */}
                {step.id < steps.length - 1 && (
                  <div className="hidden md:block absolute top-[120px] left-[50%] w-full h-0.5 bg-gray-200 -translate-y-1/2" />
                )}

                {/* Number indicator */}
                <div className="relative w-16 h-16 mb-6 bg-white rounded-full z-10">
                  <div className="relative w-full h-full">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="text-2xl font-bold text-gray-900">
                        {step.id + 1}
                      </span>
                    </div>
                    <svg className="w-full h-full" viewBox="0 0 40 40">
                      <circle
                        cx="20"
                        cy="20"
                        r="18"
                        fill="none"
                        stroke="#f3f4f6"
                        strokeWidth="4"
                      />
                      <circle
                        cx="20"
                        cy="20"
                        r="18"
                        fill="none"
                        stroke="#EF4444"
                        strokeWidth="4"
                        strokeDasharray={`${((step.id + 1) * 113) / steps.length} 200`}
                        transform="rotate(-90 20 20)"
                      />
                    </svg>
                  </div>
                </div>

                {/* Icon */}
                <div className="bg-white p-6 rounded-2xl shadow-md mb-6 relative z-10">
                  {step.icon}
                </div>

                {/* Content */}
                <h3 className="text-xl font-bold text-white mb-3">{step.title}</h3>
                <p className="text-gray-200 leading-relaxed max-w-xs mx-auto">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { X, Sun, Snowflake, Cloud, Leaf, Check, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import ComparisonTable from './ComparisonTable';
import { seasonalServices } from '../data/services';

interface Service {
  title: string;
  price: string;
  features: string[];
  details?: {
    [key: string]: string;
  };
  isSignature?: boolean;
  rateType?: string;
  description?: string;
}

interface ServiceModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  services: Service[];
  serviceType: string;
}

interface SeasonalPopupProps {
  isOpen: boolean;
  closePopup: () => void;
  season: any;
}

function SeasonalPopup({ isOpen, closePopup, season }: SeasonalPopupProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closePopup}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="absolute right-4 top-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={closePopup}
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold text-gray-900 mb-6"
                >
                  {season.title}
                </Dialog.Title>

                {/* Service Description */}
                <div className="mb-8">
                  <h5 className="font-semibold text-gray-900 mb-4">{season.title} - Detailed Information</h5>
                  <p className="text-gray-600 leading-relaxed">
                    {season.details?.description}
                  </p>
                </div>

                <div className="grid md:grid-cols-2 gap-8">
                  {/* Features */}
                  <div>
                    <h5 className="font-semibold text-gray-900 mb-4">Service Includes:</h5>
                    <div className="space-y-3">
                      {season.features.map((feature: string, index: number) => (
                        <div key={index} className="flex items-start">
                          <Check className="h-5 w-5 text-cars-red shrink-0 mt-0.5" />
                          <span className="ml-3 text-gray-600">{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Pricing and Booking */}
                  <div className="flex flex-col justify-between">
                    <div>
                      <h5 className="font-semibold text-gray-900 mb-4">Service Rate:</h5>
                      <div className="bg-gray-50 p-4 rounded-lg inline-block">
                        <div className="text-gray-500 text-sm">Starting at</div>
                        <div className="text-3xl font-bold text-cars-red">
                          ${season.price}
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`/book/seasonal/${season.title.toLowerCase().replace(/\s+/g, '-')}`}
                      className="mt-6 bg-cars-red text-white px-6 py-3 rounded-lg text-lg font-medium hover:bg-red-700 transition-colors inline-flex items-center justify-center"
                    >
                      Book {season.title}
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default function ServiceModal({ isOpen, closeModal, title, services, serviceType }: ServiceModalProps) {
  const [selectedSeason, setSelectedSeason] = useState<any>(null);

  const seasonIcons = [
    { name: "Spring-Forward", icon: Cloud, color: "text-green-500" },
    { name: "Summer Ready", icon: Sun, color: "text-yellow-500" },
    { name: "Autumn Safety", icon: Leaf, color: "text-orange-500" },
    { name: "Winter Shield", icon: Snowflake, color: "text-blue-500" }
  ];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="absolute right-4 top-4">
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={closeModal}
                    >
                      <X className="h-6 w-6" />
                    </button>
                  </div>

                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 mb-4"
                  >
                    {title}
                  </Dialog.Title>

                  {serviceType === 'pre-purchase' ? (
                    <ComparisonTable />
                  ) : serviceType === 'diagnostic' ? (
                    <div className="max-w-4xl mx-auto">
                      {services.map((service) => (
                        <div
                          key={service.title}
                          className="bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-200 overflow-hidden"
                        >
                          {/* Header */}
                          <div className="bg-cars-red/5 p-6 border-b border-gray-200">
                            <h4 className="text-2xl font-bold text-gray-900 mb-2">{service.title}</h4>
                            <div className="flex items-center gap-2 text-cars-red mb-4">
                              <Clock className="h-5 w-5" />
                              <span className="text-sm">Most diagnostics completed within 1-2 hours</span>
                            </div>
                          </div>

                          {/* Content */}
                          <div className="p-6">
                            {/* Service Description */}
                            <div className="mb-8">
                              <h5 className="font-semibold text-gray-900 mb-4">Professional Diagnostic Service - Detailed Information</h5>
                              <p className="text-gray-600 leading-relaxed">
                                {service.details?.description}
                              </p>
                            </div>

                            <div className="grid md:grid-cols-2 gap-8">
                              {/* Features */}
                              <div>
                                <h5 className="font-semibold text-gray-900 mb-4">Service Includes:</h5>
                                <div className="space-y-3">
                                  {service.features.map((feature, index) => (
                                    <div key={index} className="flex items-start">
                                      <Check className="h-5 w-5 text-cars-red shrink-0 mt-0.5" />
                                      <span className="ml-3 text-gray-600">{feature}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              {/* Pricing and Booking */}
                              <div className="flex flex-col justify-between">
                                <div>
                                  <h5 className="font-semibold text-gray-900 mb-4">Professional Rate:</h5>
                                  <div className="bg-gray-50 p-4 rounded-lg inline-block">
                                    <div className="text-gray-500 text-sm">Starting at</div>
                                    <div className="text-3xl font-bold text-cars-red">
                                      ${service.price}
                                      {service.rateType && <span className="text-lg">/hr</span>}
                                    </div>
                                  </div>
                                </div>
                                <Link
                                  to={`/book/diagnostic/${service.title.toLowerCase().replace(/\s+/g, '-')}`}
                                  className="mt-6 bg-cars-red text-white px-6 py-3 rounded-lg text-lg font-medium hover:bg-red-700 transition-colors inline-flex items-center justify-center"
                                >
                                  Book Diagnostic Service
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : serviceType === 'seasonal' ? (
                    <div className="mt-4">
                      <p className="text-gray-600 mb-8">
                        Select a season below to view detailed maintenance services tailored for Colorado's diverse weather conditions:
                      </p>
                      <div className="grid grid-cols-2 gap-8">
                        {seasonIcons.map((season, index) => {
                          const seasonData = seasonalServices.find(s => s.title === season.name);
                          const SeasonIcon = season.icon;
                          return (
                            <button
                              key={season.name}
                              onClick={() => setSelectedSeason(seasonData)}
                              className="flex flex-col items-center p-6 rounded-xl hover:bg-gray-50 transition-colors border-2 border-transparent hover:border-gray-200"
                            >
                              <SeasonIcon className={`w-24 h-24 mb-4 ${season.color}`} />
                              <span className="text-lg font-medium text-gray-900">
                                {season.name.split(' ')[0]}
                              </span>
                              <span className="text-lg text-cars-red font-medium mt-2">
                                ${seasonData?.price}
                              </span>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col lg:flex-row gap-4 overflow-x-auto pb-4">
                      {services.map((service) => (
                        <div
                          key={service.title}
                          className={`flex-1 min-w-[300px] bg-gray-50 p-6 rounded-xl border-2 ${
                            service.isSignature ? 'border-cars-red' : 'border-transparent'
                          }`}
                        >
                          <div className="flex justify-between items-start mb-4">
                            <h4 className="text-xl font-bold text-gray-900">{service.title}</h4>
                            {service.isSignature && (
                              <span className="bg-cars-red text-white text-xs px-2 py-1 rounded-full">
                                Signature
                              </span>
                            )}
                          </div>
                          
                          <div className="space-y-3 mb-6">
                            {service.features.map((feature, index) => (
                              <div key={index} className="flex items-start">
                                <Check className="h-5 w-5 text-cars-red shrink-0 mt-0.5" />
                                <span className="ml-3 text-gray-600">{feature}</span>
                              </div>
                            ))}
                          </div>

                          <div className="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
                            <div className="text-center sm:text-left">
                              <span className="text-gray-500 text-sm">Starting at</span>
                              <p className="text-2xl font-bold text-cars-red">
                                ${service.price}
                                {service.rateType && <span className="text-lg">/hr</span>}
                              </p>
                            </div>
                            <Link
                              to={`/book/${serviceType}/${service.title.toLowerCase().replace(/\s+/g, '-')}`}
                              className="w-full sm:w-auto bg-cars-red text-white px-6 py-2.5 rounded-lg text-sm font-medium hover:bg-red-700 transition-colors inline-flex items-center justify-center"
                            >
                              Book Now
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {selectedSeason && (
        <SeasonalPopup
          isOpen={!!selectedSeason}
          closePopup={() => setSelectedSeason(null)}
          season={selectedSeason}
        />
      )}
    </>
  );
}

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Mail, Car, Star } from 'lucide-react';
import InstagramSlideshow from './InstagramSlideshow';

export default function Hero() {
  return (
    <div className="relative bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-1/2 lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-center lg:text-left"
              >
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block">Colorado's Premier</span>
                  <span className="block text-cars-red">Auto Inspection Service</span>
                </h1>
                <p className="mt-3 text-2xl font-bold text-gray-800 sm:mt-5 md:mt-5">
                  Driven by passion, guided by experience:
                </p>
                <p className="mt-2 text-base text-gray-500 sm:text-lg md:text-xl">
                  Professional mobile automotive services with transparent pricing and exceptional customer service. Serving Denver and surrounding areas.
                </p>
                <div className="mt-5 sm:mt-8 flex flex-col sm:flex-row justify-center lg:justify-start gap-4">
                  <Link
                    to="/contact"
                    className="w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-cars-red hover:bg-red-700 md:text-lg transition-all duration-300"
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/services"
                    className="w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 border-2 border-cars-red text-base font-medium rounded-md text-cars-red bg-white hover:bg-red-50 md:text-lg transition-all duration-300"
                  >
                    Our Services
                  </Link>
                </div>

                {/* Social Media Links */}
                <div className="mt-8 pt-6 border-t border-gray-200">
                  <p className="text-base text-gray-600 mb-4 text-center lg:text-left">Follow us on social media</p>
                  <div className="flex justify-center lg:justify-start space-x-6">
                    <a href="https://www.facebook.com/people/Colorado-Automotive-Report-Service/61565985998267/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-cars-red" aria-label="Facebook">
                      <Facebook className="w-6 h-6" />
                    </a>
                    <a href="https://www.instagram.com/autoreportserv/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-cars-red" aria-label="Instagram">
                      <Instagram className="w-6 h-6" />
                    </a>
                    <a href="https://www.google.com/maps/place/Colorado+Automotive+Report+%26+Service+LLC/@39.6696322,-104.9330448,17z/data=!3m1!4b1!4m6!3m5!1s0x876c7f9e116f80d7:0x644759ec8d7219f2!8m2!3d39.6696322!4d-104.9330448!16s%2Fg%2F11wfg9d6h3?entry=ttu" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-cars-red" aria-label="Google Maps">
                      <Car className="w-6 h-6" />
                    </a>
                    <a href="https://www.yelp.com/biz/colorado-automotive-report-and-service-denver-2" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-cars-red" aria-label="Yelp">
                      <Star className="w-6 h-6" />
                    </a>
                    <a href="mailto:info@autoreportservice.com" className="text-gray-600 hover:text-cars-red" aria-label="Email">
                      <Mail className="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </motion.div>
            </main>
          </div>

          <div className="lg:w-1/2">
            <div className="relative flex items-center justify-center p-4">
              <InstagramSlideshow />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
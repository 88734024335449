import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Shield, Home as HomeIcon, CheckSquare } from 'lucide-react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import ServiceCards from '../components/ServiceCards';
import Footer from '../components/Footer';
import ServiceSlider from '../components/ServiceSlider';
import ScrollingBanner from '../components/ScrollingBanner';
import Process from '../components/Process';

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>C.A.R.S - Professional Auto Inspection Services in Denver | Colorado Automotive Report & Service</title>
        <meta name="description" content="Expert auto inspection services in Denver Metro Area. Pre-purchase inspections, diagnostics, and maintenance by certified professionals. Get peace of mind with C.A.R.S." />
        <meta name="keywords" content="car inspection Denver, auto inspection Colorado, pre-purchase car inspection, vehicle diagnostic services, professional auto inspection" />
        <link rel="canonical" href="https://autoreportserv.co" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "C.A.R.S - Colorado Automotive Report & Service",
              "description": "Expert auto inspection services in Denver Metro Area",
              "provider": {
                "@type": "LocalBusiness",
                "name": "Colorado Automotive Report & Service",
                "image": "https://i.ibb.co/rfR4yw3/Logo-cleaned.png",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Denver",
                  "addressRegion": "CO",
                  "addressCountry": "US"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "39.7392",
                  "longitude": "-104.9903"
                },
                "url": "https://autoreportserv.co",
                "telephone": "(303) 908-9815",
                "priceRange": "$$"
              },
              "mainEntity": {
                "@type": "Service",
                "name": "Auto Inspection Services",
                "offers": {
                  "@type": "Offer",
                  "price": "149.99",
                  "priceCurrency": "USD"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
        <Navbar />
        <Hero>
          <div className="bg-white/95 backdrop-blur-sm p-8 rounded-lg shadow-xl border border-gray-100">
            <h1 className="text-5xl font-bold mb-6 text-gray-800">Colorado's Premier Auto Inspection Service</h1>
            <div className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">Driven by passion, guided by experience:</h2>
              <p className="text-xl mb-8 text-gray-700">Your path to smart car buying</p>
              <div className="flex gap-4">
                <button 
                  onClick={() => navigate('/book')}
                  className="bg-cars-red hover:bg-red-700 text-white font-bold py-3 px-8 rounded-lg transition-colors shadow-lg hover:shadow-xl"
                >
                  Book Inspection
                </button>
                <button 
                  onClick={() => navigate('/services')}
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-3 px-8 border-2 border-gray-300 hover:border-gray-400 rounded-lg transition-all shadow-lg hover:shadow-xl"
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="flex justify-center gap-12 mt-8">
              <div className="flex items-center gap-2">
                <Shield className="w-6 h-6 text-cars-red" />
                <span className="text-gray-800 font-medium">ASE Certified</span>
              </div>
              <div className="flex items-center gap-2">
                <HomeIcon className="w-6 h-6 text-cars-red" />
                <span className="text-gray-800 font-medium">Locally Owned & Operated</span>
              </div>
              <div className="flex items-center gap-2">
                <CheckSquare className="w-6 h-6 text-cars-red" />
                <span className="text-gray-800 font-medium">200+ Point Check</span>
              </div>
            </div>
          </div>
        </Hero>
        <div className="mt-8">
          <ScrollingBanner />
        </div>
        <div className="bg-blue-50/50">
          <div className="container mx-auto px-4 py-16">
            <ServiceSlider />
          </div>
        </div>
        <div className="bg-white">
          <ServiceCards />
        </div>
        <div className="bg-blue-50/50">
          <div className="container mx-auto px-4 py-16">
            <Process />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
import { Mail, Phone, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../data/blogPosts';

export default function Footer() {
  // Get the 3 most recent blog posts
  const latestPosts = [...blogPosts]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 3);

  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-xl font-bold mb-6 text-cars-red">About Us</h3>
            <div className="space-y-2">
              <p className="text-gray-300">C.A.R.S Colorado Automotive Report & Service LLC</p>
              <p className="text-gray-300">Denver, Colorado</p>
            </div>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-bold mb-6 text-cars-red">Contact</h3>
            <div className="space-y-4">
              <a 
                href="mailto:AFox@AutoReportServ.CO" 
                className="flex items-center text-gray-300 hover:text-white transition-colors duration-300 ease-in-out"
              >
                <Mail className="w-5 h-5 mr-2" />
                AFox@AutoReportServ.CO
              </a>
              <a 
                href="tel:+13039089815" 
                className="flex items-center text-gray-300 hover:text-white transition-colors duration-300 ease-in-out"
              >
                <Phone className="w-5 h-5 mr-2" />
                (303) 908-9815
              </a>
              <div className="flex items-center text-gray-300">
                <MapPin className="w-5 h-5 mr-2" />
                Denver, Colorado
              </div>
            </div>
          </div>

          {/* Blog Posts */}
          <div>
            <h3 className="text-xl font-bold mb-6 text-cars-red">Latest Articles</h3>
            <ul className="space-y-4">
              {latestPosts.map((post) => (
                <li key={post.slug}>
                  <Link 
                    to={`/blog/${post.slug}`} 
                    className="text-gray-300 hover:text-white block transition-colors duration-300 ease-in-out"
                  >
                    {post.title}
                    <span className="text-sm text-gray-400 block mt-1">
                      {new Date(post.date).toLocaleDateString('en-US', { 
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-xl font-bold mb-6 text-cars-red">Legal</h3>
            <ul className="space-y-4">
              <li>
                <Link 
                  to="/privacy-policy" 
                  className="text-gray-300 hover:text-white transition-colors duration-300 ease-in-out"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/terms-conditions" 
                  className="text-gray-300 hover:text-white transition-colors duration-300 ease-in-out"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link 
                  to="/accessibility" 
                  className="text-gray-300 hover:text-white transition-colors duration-300 ease-in-out"
                >
                  Accessibility Statement
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 pt-8 border-t border-gray-800">
        <p className="text-center text-gray-400 text-sm">
          {new Date().getFullYear()} C.A.R.S Colorado Automotive Report & Service LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

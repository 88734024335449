import React from 'react';
import { Routes, Route, createRoutesFromElements, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import ScrollToTop from './components/ScrollToTop';
import Layout from './components/Layout';

// Pages
import Home from './pages/Home';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import WhyChooseUs from './pages/WhyChooseUs';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Accessibility from './pages/Accessibility';
import FAQPage from './pages/FAQPage';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

// Service Pages
import PrePurchaseServices from './pages/PrePurchaseServices';
import SeasonalServices from './pages/SeasonalServices';
import DiagnosticServices from './pages/DiagnosticServices';
import FleetServices from './pages/FleetServices';
import ElectricalServices from './pages/ElectricalServices';
import PerformanceServices from './pages/PerformanceServices';
import OilAnalysis from './pages/OilAnalysis';
import TestDrive from './pages/TestDrive';
import MarketValue from './pages/MarketValue';
import PackageDetails from './pages/PackageDetails';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/why-choose-us" element={<WhyChooseUs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:slug" element={<BlogPost />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/faq" element={<FAQPage />} />
      
      {/* Service Type Routes */}
      <Route path="/services/pre-purchase" element={<PrePurchaseServices />} />
      <Route path="/services/seasonal" element={<SeasonalServices />} />
      <Route path="/services/diagnostic" element={<DiagnosticServices />} />
      <Route path="/services/fleet" element={<FleetServices />} />
      <Route path="/services/electrical" element={<ElectricalServices />} />
      <Route path="/services/performance" element={<PerformanceServices />} />
      <Route path="/services/oil-analysis" element={<OilAnalysis />} />
      <Route path="/services/test-drive" element={<TestDrive />} />
      <Route path="/services/market-value" element={<MarketValue />} />
      <Route path="/services/package-details" element={<PackageDetails />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  }
);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-right" />
    </HelmetProvider>
  );
}

export default App;
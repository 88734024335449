import React, { createContext, useContext, useState } from 'react';

export interface AddOn {
  id: string;
  name: string;
  price: number;
  type: 'addon';
  category: 'documentation' | 'testing' | 'inspection' | 'analysis';
}

export interface ServicePackage {
  id: string;
  name: string;
  price: number;
  description: string;
  features: string[];
  type: 'pre-purchase' | 'seasonal' | 'diagnostic';
}

export interface Vehicle {
  id: string;
  make: string;
  model: string;
  year: number;
  vin: string;
  mileage: number;
}

interface CartContextType {
  addOns: AddOn[];
  selectedPackage: ServicePackage | null;
  selectedVehicle: Vehicle | null;
  total: number;
  addAddOn: (addon: AddOn) => void;
  removeAddOn: (addonId: string) => void;
  setPackage: (pkg: ServicePackage | null) => void;
  setSelectedVehicle: (vehicle: Vehicle | null) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [addOns, setAddOns] = useState<AddOn[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<ServicePackage | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const addAddOn = (addon: AddOn) => {
    setAddOns(prev => [...prev, addon]);
  };

  const removeAddOn = (addonId: string) => {
    setAddOns(prev => prev.filter(addon => addon.id !== addonId));
  };

  const setPackage = (pkg: ServicePackage | null) => {
    setSelectedPackage(pkg);
  };

  const total = (selectedPackage?.price || 0) + addOns.reduce((sum, addon) => sum + addon.price, 0);

  return (
    <CartContext.Provider value={{
      addOns,
      selectedPackage,
      selectedVehicle,
      total,
      addAddOn,
      removeAddOn,
      setPackage,
      setSelectedVehicle,
    }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}

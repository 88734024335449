import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

// Import images
import photo1 from '../assets/instagram/20241022_162132.jpg';
import photo2 from '../assets/instagram/IMG_0694.jpg';
import photo3 from '../assets/instagram/IMG_1169.jpg';
import photo4 from '../assets/instagram/IMG_1350.jpg';

// Instagram photos array
const instagramPhotos = [
  {
    id: 1,
    url: photo1,
    alt: 'Auto Report Service 1'
  },
  {
    id: 2,
    url: photo2,
    alt: 'Auto Report Service 2'
  },
  {
    id: 3,
    url: photo3,
    alt: 'Auto Report Service 3'
  },
  {
    id: 4,
    url: photo4,
    alt: 'Auto Report Service 4'
  }
];

const InstagramSlideshow: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'linear',
    arrows: false,
  };

  return (
    <div className="w-full max-w-md mx-auto aspect-[4/3] rounded-lg shadow-lg">
      <Slider {...settings}>
        {instagramPhotos.map((photo) => (
          <div key={photo.id} className="relative w-full h-full">
            <img
              src={photo.url}
              alt={photo.alt}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InstagramSlideshow;

import { Check, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const features = [
  'Body Inspection',
  'Mechanical Check',
  'Fluid Checks',
  'Diagnostic Scan',
  'Battery Test',
  'Interior Inspection',
  'Electronics Functionality',
  'Brake Measurement',
  'Suspension Check',
  'Video Documentation',
  'Interactive Report',
  'Phone Consultation',
  'Hybrid & EV Testing'
];

const packages = [
  {
    name: 'Essential Check',
    price: '149.99',
    description: 'Basic inspection covering essential vehicle components',
    features: {
      'Body Inspection': true,
      'Mechanical Check': false,
      'Fluid Checks': true,
      'Diagnostic Scan': true,
      'Battery Test': true,
      'Interior Inspection': true,
      'Electronics Functionality': true,
      'Brake Measurement': true,
      'Suspension Check': false,
      'Video Documentation': false,
      'Interactive Report': false,
      'Phone Consultation': false,
      'Hybrid & EV Testing': false
    }
  },
  {
    name: 'Complete Report',
    price: '249.99',
    description: 'Comprehensive check with detailed mechanical inspection',
    features: {
      'Body Inspection': true,
      'Mechanical Check': true,
      'Fluid Checks': true,
      'Diagnostic Scan': true,
      'Battery Test': true,
      'Interior Inspection': true,
      'Electronics Functionality': true,
      'Brake Measurement': true,
      'Suspension Check': true,
      'Video Documentation': false,
      'Interactive Report': false,
      'Phone Consultation': false,
      'Hybrid & EV Testing': false
    }
  },
  {
    name: 'Signature Package',
    price: '399.99',
    description: 'Premium inspection with video documentation and interactive report',
    features: {
      'Body Inspection': true,
      'Mechanical Check': true,
      'Fluid Checks': true,
      'Diagnostic Scan': true,
      'Battery Test': true,
      'Interior Inspection': true,
      'Electronics Functionality': true,
      'Brake Measurement': true,
      'Suspension Check': true,
      'Video Documentation': true,
      'Interactive Report': true,
      'Phone Consultation': true,
      'Hybrid & EV Testing': true
    },
    isSignature: true
  },
  {
    name: 'Elite Package',
    price: '749.99',
    description: 'Ultimate inspection with expert consultation',
    features: {
      'Body Inspection': true,
      'Mechanical Check': true,
      'Fluid Checks': true,
      'Diagnostic Scan': 'Factory Software',
      'Battery Test': true,
      'Interior Inspection': true,
      'Electronics Functionality': true,
      'Brake Measurement': true,
      'Suspension Check': true,
      'Video Documentation': true,
      'Interactive Report': true,
      'Phone Consultation': true,
      'Hybrid & EV Testing': true
    }
  }
];

export default function ComparisonTable() {
  const getFeatureDisplay = (pkg: any, hasFeature: boolean, feature: string) => {
    // Special case for Elite Package features
    if (pkg.name === 'Elite Package' && typeof hasFeature === 'string') {
      return <span className="text-sm text-gray-700">{hasFeature}</span>;
    }

    return hasFeature ? (
      <Check className="mx-auto h-5 w-5 text-cars-red" />
    ) : (
      <X className="mx-auto h-5 w-5 text-gray-300" />
    );
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-4 gap-4">
        {packages.map((pkg) => (
          <div key={pkg.name} className={`text-center p-4 rounded-lg ${pkg.isSignature ? '' : 'bg-gray-50'}`}>
            <h3 className={`text-lg font-bold mb-2 ${pkg.isSignature ? 'text-cars-red' : 'text-gray-900'}`}>
              {pkg.name}
            </h3>
            <p className="text-sm text-gray-600">{pkg.description}</p>
          </div>
        ))}
      </div>

      <div className="w-full overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">
                Features
              </th>
              {packages.map((pkg) => (
                <th
                  key={pkg.name}
                  scope="col"
                  className={`px-3 py-3.5 text-center text-sm font-semibold ${
                    pkg.isSignature ? 'text-cars-red' : 'text-gray-900'
                  }`}
                >
                  {pkg.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {Object.entries(packages[0].features).map(([feature], index) => (
              <tr key={feature} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="py-3 pl-6 pr-3 text-sm font-medium text-gray-900">{feature}</td>
                {packages.map((pkg) => (
                  <td key={pkg.name} className={`px-4 py-3 text-center ${pkg.isSignature ? '' : ''}`}>
                    {getFeatureDisplay(pkg, pkg.features[feature], feature)}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="bg-[#1a1f2e] text-white">
              <td className="py-4 pl-6 pr-3 text-sm font-semibold">Price</td>
              {packages.map((pkg) => (
                <td key={pkg.name} className="px-4 py-4 text-center">
                  <div className="text-lg font-semibold">{pkg.price}</div>
                  <div className="text-sm text-gray-300">One-time fee</div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="text-center mt-8 space-y-4">
        <div className="bg-gray-50 rounded-lg p-4 max-w-2xl mx-auto">
          <p className="text-base text-gray-700 mb-2">
            <span className="font-medium text-cars-red">Add-ons Available</span> for Complete Report, Signature Package, and Elite Package
          </p>
          <p className="text-sm text-gray-600">
            Customize your inspection with additional services
          </p>
        </div>
        <Link
          to="/contact"
          className="inline-block bg-cars-red text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors"
        >
          Contact Us For Service
        </Link>
        <p className="text-sm text-gray-600">Get in touch with us to schedule your inspection and discuss add-on options</p>
      </div>
    </div>
  );
}

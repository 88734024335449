import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const SLIDE_DURATION = 7000;

const slides = [
  {
    image: "https://i.ibb.co/tHYp39c/inspecttt.jpg",
    title: "Pre-Purchase Inspections",
    description: "Make confident buying decisions with our thorough vehicle inspections",
    link: "/services",
    icon: "Shield",
    price: "$149"
  },
  {
    image: "https://i.ibb.co/tMhrPMt/4-seasons.jpg",
    title: "Seasonal Maintenance",
    description: "Keep your vehicle in top condition throughout the year",
    link: "/services#seasonal",
    icon: "Wrench",
    price: "$199"
  },
  {
    image: "https://i.ibb.co/MgfHSGz/diag.jpg",
    title: "Diagnostic Services",
    description: "Advanced diagnostics to identify and solve complex issues",
    link: "/services#diagnostic",
    icon: "Tool",
    price: "$99"
  }
];

export default function ServiceSlider() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [imageError, setImageError] = useState<Record<number, boolean>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, SLIDE_DURATION);
    return () => clearInterval(timer);
  }, []);

  const handleImageError = (index: number) => {
    setImageError(prev => ({ ...prev, [index]: true }));
  };

  return (
    <div className="relative h-[600px] overflow-hidden py-8 bg-white">
      <AnimatePresence mode="wait">
        {slides.map((slide, index) => (
          index === activeSlide && (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-black/30 z-10" />
              {!imageError[index] ? (
                <motion.img
                  initial={{ scale: 1.1 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 8 }}
                  src={slide.image}
                  alt={slide.title}
                  onError={() => handleImageError(index)}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gray-100 flex items-center justify-center border border-gray-200">
                  <span className="text-gray-600 text-lg">Image unavailable</span>
                </div>
              )}
              <div className="absolute inset-0 z-20 flex items-center">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl bg-black/30 backdrop-blur-sm p-8 rounded-lg"
                  >
                    <div className="flex items-center gap-4">
                      <div>
                        <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">{slide.title}</h1>
                        <p className="text-xl text-white/90">{slide.description}</p>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          )
        ))}
      </AnimatePresence>
      
      <div className="absolute bottom-8 left-1/2 -translate-x-1/2 z-30 flex gap-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === activeSlide 
                ? 'bg-white w-8' 
                : 'bg-white/50 hover:bg-white/80'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}
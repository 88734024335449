import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../components/Breadcrumb';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ChevronDown, ChevronUp, HelpCircle, HeadphonesIcon, Car, X } from 'lucide-react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQSection {
  title: string;
  icon: JSX.Element;
  questions: FAQItem[];
}

const faqSections: FAQSection[] = [
  {
    title: "General Questions",
    icon: <HelpCircle size={64} className="text-cars-red" />,
    questions: [
      {
        question: "What types of vehicle inspections do you offer?",
        answer: "Our company provides a range of automotive services, including comprehensive pre-purchase inspections, diagnostic assessments, seasonal maintenance evaluations, and specialized inspections for fleet vehicles. Each service is customized to offer a thorough understanding of the vehicle's condition."
      },
      {
        question: "What's included in a pre-purchase inspection?",
        answer: "Our pre-purchase inspection includes a thorough examination of the engine, transmission, electrical systems, suspension, brakes, and body condition. We also perform computer diagnostics and provide a detailed report with photos."
      },
      {
        question: "How much does an inspection cost?",
        answer: "Our inspection services start at $149.99 for basic inspections. Prices vary based on the type of service, vehicle make/model, and inspection package selected. We offer different packages to suit various needs and budgets. Contact us for a detailed quote based on your specific requirements."
      },
      {
        question: "Do you come to my location for the inspection?",
        answer: "Yes, we offer mobile inspection services throughout the Denver Metro area. We can perform inspections at your home, workplace, or the seller's location for pre-purchase inspections."
      },
      {
        question: "How long does a typical inspection take?",
        answer: "A standard inspection typically requires one to two hours to complete. However, a more comprehensive inspection may necessitate two to three hours. Our commitment is to a meticulous examination of all critical components, ensuring a thorough evaluation without compromising the necessary time for a proper assessment."
      },
      {
        question: "Are your inspectors certified?",
        answer: "Yes, all our inspectors are ASE (Automotive Service Excellence) certified professionals with extensive experience in vehicle diagnostics and inspections. They undergo regular training to stay current with the latest automotive technologies and inspection techniques."
      },
      {
        question: "How do I schedule an inspection?",
        answer: "You can schedule an inspection through our online request system or by phone. We'll work with you to find a convenient time and location. We recommend booking at least 24-48 hours in advance."
      },
      {
        question: "What happens after the inspection?",
        answer: "Following the inspection, you'll receive a detailed digital report including photos, findings, and recommendations. Our inspector will review the results with you and answer any questions. If issues are found, we'll provide expert advice on potential repairs and their urgency."
      },
      {
        question: "Do you offer weekend appointments?",
        answer: "Yes, we offer weekend appointments to accommodate our clients' schedules. However, weekend slots tend to fill up quickly, so we recommend booking these appointments well in advance."
      },
      {
        question: "What forms of payment do you accept?",
        answer: "We accept all major credit cards, debit cards, and digital payment methods including Apple Pay and Google Pay. Payment is required at the time of confirmation of scheduling inspection with seller."
      }
    ]
  },
  {
    title: "Mobile Service Questions",
    icon: <Car size={64} className="text-cars-red" />,
    questions: [
      {
        question: "What is a mobile car inspection and why is it important for used cars?",
        answer: "A mobile car inspection is a service where a qualified mechanic or inspector comes to your location to inspect a used vehicle before you decide to buy a used car. This on-site service is crucial as it provides an opportunity to assess the vehicle's condition without the need to drive it to a shop. By getting a pre-purchase inspection, you can identify any potential issues that could lead to costly repairs in the future, ensuring you have peace of mind regarding your vehicle purchase."
      },
      {
        question: "How does a pre-purchase inspection differ from a standard car inspection?",
        answer: "A pre-purchase inspection specifically targets used cars that a buyer is considering for purchase. It involves a comprehensive check of the vehicle's mechanical and structural integrity. While a standard car inspection may focus on routine maintenance checks, a pre-purchase inspection is tailored to uncover any hidden defects that could affect the used car's value or safety. This detailed assessment often includes a review of the vehicle history report and a road test to ensure everything functions well."
      },
      {
        question: "What should I expect during a mobile used car inspection?",
        answer: "During a mobile used car inspection, expect a thorough examination of the vehicle, including its exterior, interior, and mechanical components. The inspector will check for signs of damage, rust, or previous accidents, as well as evaluate the engine, transmission, brakes, and tires. Additionally, they may conduct a test drive to assess the vehicle's performance. At the end of the inspection, you will receive a detailed inspection report outlining the findings, which helps you make an informed decision regarding your used car purchase. Details are provided in the details of each service package"
      },
      {
        question: "Do you come to my location for the inspection?",
        answer: "Yes, we offer mobile inspection services throughout the Denver Metro area. We can perform inspections at your home, workplace, or the seller's location for pre-purchase inspections."
      },
      {
        question: "What areas do you service?",
        answer: "We provide mobile inspection services across the Denver Metro area, including Denver, Aurora, Lakewood, Centennial, Littleton, and surrounding suburbs. Contact us to confirm service availability in your specific location."
      },
      {
        question: "What do I need to provide for a mobile inspection?",
        answer: "For a mobile inspection, we just need a safe, flat surface with enough space to work around the vehicle. This could be your driveway, parking lot, or garage. Make sure the vehicle is accessible and that you or an authorized representative will be present during the inspection."
      },
      {
        question: "How long does a mobile inspection take?",
        answer: "A typical mobile inspection takes between 1-2 hours, depending on the vehicle type and service package selected. We'll provide you with an estimated completion time when scheduling your appointment."
      }
    ]
  },
  {
    title: "Hybrid/EV Specific Questions",
    icon: <Car size={64} className="text-cars-red" />,
    questions: [
      {
        question: "What is the importance of inspecting an electric vehicle?",
        answer: "Inspecting an electric vehicle (EV) is crucial for several reasons. First, it helps ensure that the battery and associated systems are functioning correctly, as they are key components of an electric vehicle. Regular inspections can identify any potential issues with the battery pack or electric motor, which can prevent costly repairs and maintain the vehicle's performance. Additionally, electric vehicles may have unique features, such as regenerative braking systems, that require specialized knowledge during inspection. Ensuring that the electric motor and charging systems are in optimal condition contributes to the overall fuel economy and driving range of the vehicle."
      },
      {
        question: "What should I look for when inspecting a hybrid vehicle?",
        answer: "When inspecting a hybrid vehicle, several key components are evaluated. Start with the battery status; check the health of the High Voltage battery and ensure it State of Health. Inspect the combustion engine and make sure it is functioning properly, as hybrid vehicles rely on both the electric motor and gasoline engine. Additionally, evaluate the charger if available and ensure the charging station is working correctly. Look for any signs of wear and tear in the powertrain and check the fuel economy metrics to ensure they align with the manufacturer's specifications. (On Certain Packages)"
      },
      {
        question: "How often should I have my electric car inspected?",
        answer: "It is recommended to have your electric car inspected at least once a year, although more frequent checks might be advisable depending on usage and manufacturer guidelines. Routine checks help maintain the health of the batteries and electrical systems, ensure the electric motor is functioning correctly, and verify that the charging station and charger are in good condition. If you notice any performance issues, such as decreased driving range or unusual noises, you should seek an inspection sooner."
      },
      {
        question: "What are the common issues found during hybrid electric vehicle inspections?",
        answer: "During inspections of plug-in hybrid electric vehicles (PHEVs), common issues often arise that can impact performance and safety. A prevalent concern is the condition of the lithium-ion battery, which plays a critical role in maintaining the vehicle's electric range. If the High Voltage Battery does not have a good State of Health, it can lead to decreased battery power and affect the vehicle's ability to charge the battery effectively. Additionally, hybrid and electric vehicles may face challenges with their electric drive systems or one or more electric motors, leading to performance issues."
      },
      {
        question: "Do you inspect electric and hybrid vehicles?",
        answer: "Yes, we have certified technicians specifically trained in electric and hybrid vehicle inspections. We use specialized diagnostic equipment for these advanced powertrains."
      },
      {
        question: "What specific checks do you perform for EVs?",
        answer: "For EVs, we inspect the battery health, charging system, power inverter, electric motor(s), regenerative braking system, and all electronic control systems. We also verify the estimated range and charging capabilities."
      }
    ]
  }
];

const breadcrumbItems = [
  { name: 'FAQ', path: '/faq' }
];

// Generate FAQ Schema
const faqSchema = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqSections.flatMap(section => 
    section.questions.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer
      }
    }))
  )
};

export default function FAQPage() {
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);

  const closeModal = () => {
    setSelectedSection(null);
    setSelectedQuestion(null);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Frequently Asked Questions | C.A.R.S - Auto Inspection Services Denver</title>
        <meta name="description" content="Find answers to common questions about our auto inspection services in Denver. Learn about our pre-purchase inspections, diagnostic services, and more." />
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>

      <Navbar />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Breadcrumb items={breadcrumbItems} />
        
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mt-8">Frequently Asked Questions</h1>
          <p className="mt-4 text-xl text-gray-600">Find answers to common questions about our services</p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {faqSections.map((section, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white rounded-xl shadow-lg p-8 cursor-pointer hover:shadow-xl transition-shadow duration-300 flex flex-col items-center"
              onClick={() => setSelectedSection(index)}
            >
              <div className="mb-6 transform transition-transform duration-300 hover:scale-110">
                {section.icon}
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">{section.title}</h2>
              <p className="text-gray-600 text-center">Click to view {section.questions.length} questions</p>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedSection !== null && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
              onClick={closeModal}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-xl shadow-2xl p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto"
                onClick={e => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">{faqSections[selectedSection].title}</h2>
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <X size={24} />
                  </button>
                </div>

                <div className="space-y-4">
                  {faqSections[selectedSection].questions.map((faq, questionIndex) => (
                    <div
                      key={questionIndex}
                      className="border rounded-lg hover:border-cars-red transition-colors duration-200"
                    >
                      <button
                        className="w-full px-4 py-3 text-left flex justify-between items-center focus:outline-none"
                        onClick={() => setSelectedQuestion(selectedQuestion === questionIndex ? null : questionIndex)}
                      >
                        <h3 className="text-lg font-semibold text-gray-900">{faq.question}</h3>
                        {selectedQuestion === questionIndex ? (
                          <ChevronUp className="w-5 h-5 text-cars-red" />
                        ) : (
                          <ChevronDown className="w-5 h-5 text-cars-red" />
                        )}
                      </button>
                      <AnimatePresence>
                        {selectedQuestion === questionIndex && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="overflow-hidden"
                          >
                            <div className="px-4 pb-4">
                              <p className="text-gray-600">{faq.answer}</p>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="mt-12 bg-blue-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Still have questions?</h2>
          <p className="text-gray-600 mb-6">
            Our team is here to help. Contact us for personalized assistance.
          </p>
          <Link
            to="/contact"
            className="text-cars-red hover:text-red-700"
          >
            contact us
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

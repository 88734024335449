import { Search, Wrench, Thermometer } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    id: 1,
    title: "Pre-Purchase Inspections",
    description: "Make informed decisions with our comprehensive inspection packages. From basic checks to detailed reports, we ensure you know exactly what you're buying.",
    icon: Search,
    price: "Starting at $149.99",
    link: "/services",
    serviceType: 'pre-purchase'
  },
  {
    id: 2,
    title: "Seasonal Maintenance",
    description: "Keep your vehicle running smoothly all year round with our seasonal maintenance packages. We help protect your car against Colorado's diverse weather conditions.",
    icon: Thermometer,
    price: "Starting at $139.99",
    link: "/services",
    serviceType: 'seasonal'
  },
  {
    id: 3,
    title: "Professional Diagnostic Service",
    description: "State-of-the-art diagnostic service including full system scans, live data analysis, and comprehensive testing. Expert technicians deliver detailed insights about your vehicle's health.",
    icon: Wrench,
    price: "$199.99/hr",
    link: "/services/diagnostic",
    serviceType: 'diagnostic'
  }
];

export default function ServiceCards() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-[#1a1f2e] rounded-3xl mx-8 p-12">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">
              Our Services
            </h2>
            <p className="text-xl text-gray-200">
              Professional auto services tailored to your needs
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service) => (
              <div
                key={service.id}
                className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <Link to={service.link} className="block h-full">
                  <div className="p-8">
                    <div className="flex items-center justify-center w-12 h-12 bg-cars-red rounded-xl mb-6">
                      <service.icon className="w-6 h-6 text-white" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-3">{service.title}</h3>
                    <p className="text-gray-600 mb-4">{service.description}</p>
                    <div className="text-lg font-semibold text-cars-red">{service.price}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
